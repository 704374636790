@use "tokens/colors";
// @import "./tokens/spacing";
// @import "./tokens/typography";

:root {
  --background-color: #fbfbf5;
  --text-color: #000;

  --h1-font: neue-haas-grotesk-display, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --paragraph: neue-haas-grotesk-text, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --h2-font: neue-haas-grotesk-display, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --h3-font: neue-haas-grotesk-display, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --h4-font: neue-haas-grotesk-display, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --h5-font: neue-haas-grotesk-display, "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --light-text: var(--paragraph);

  //Colors default to light mode
  --primary-color: #5e2751; //Cesta Purple
  --secondary-color: #eab8e4; //Cesta Light Purple
  --tertiary-color: #0c2340; // Midnight Blue
  --light-accent: #fefced; // Cream
  --lightest-accent: #fffef8; // Light Cream

  --text-color: #000000;
  --help-text-color: rgba(0, 0, 0, 0.5);
  --background-color: var(--lightest-accent);
  --default-background-color: #dae0d7;
  --default-link-color: #000;
  --light-text-color: var(--light-accent);

  --image-placeholder-color: #e5e0cd;

  --ui-spacer-1: 0.25rem;
  --ui-spacer-2: 0.5rem;
  --ui-spacer-3: 0.75rem;
  --ui-spacer-4: 1rem;
  --ui-spacer-5: 1.25rem;
  --ui-spacer-6: 1.5rem;
  --ui-spacer-7: 1.75rem;
  --ui-spacer-8: 2rem;
  --ui-spacer-9: 2.25rem;
  --ui-spacer-10: 2.5rem;
  --ui-spacer-11: 2.75rem;
  --ui-spacer-12: 3rem;
  --ui-spacer-13: 3.25rem;
  --ui-spacer-14: 3.5rem;
  --ui-spacer-15: 3.75rem;
  --ui-spacer-16: 4rem;
  --ui-spacer-17: 4.25rem;
  --ui-spacer-18: 4.5rem;
  --ui-spacer-19: 4.75rem;
  --ui-spacer-20: 5rem;
  --ui-spacer-21: 5.25rem;
  --ui-spacer-22: 5.5rem;
  --ui-spacer-23: 5.75rem;
  --ui-spacer-24: 6rem;
  --ui-spacer-25: 6.25rem;
  --ui-spacer-26: 6.5rem;
  --ui-spacer-27: 6.75rem;
  --ui-spacer-28: 7rem;
  --ui-spacer-29: 7.25rem;
  --ui-spacer-30: 7.5rem;
  --ui-typography-h1-family: var(--h1-font);
  --ui-typography-h1-size: clamp(2rem, 10vw, 3.5rem);
  --ui-typography-h1-weight: 900;
  --ui-typography-h1-line-height: 0.9;
  --ui-typography-h1-letter-spacing: 0;
  --ui-typography-h1-color: rgba(25, 25, 25, 1);
  --ui-typography-h2-family: var(--h2-font);
  --ui-typography-h2-size: clamp(2.5rem, 8vw, 2.5rem);
  --ui-typography-h2-weight: 700;
  --ui-typography-h2-line-height: 1;
  --ui-typography-h2-letter-spacing: 0;
  --ui-typography-h2-color: rgba(0, 0, 0, 1);
  --ui-typography-h3-family: var(--h3-font);
  --ui-typography-h3-size: clamp(1.25rem, 3vw, 1.7rem);
  --ui-typography-h3-weight: 700;
  --ui-typography-h3-line-height: 1;
  --ui-typography-h3-letter-spacing: 0;
  --ui-typography-h3-color: rgba(0, 0, 0, 1);
  --ui-typography-h4-family: var(--h4-font);
  --ui-typography-h4-size: 1.25rem;
  --ui-typography-h4-weight: 500;
  --ui-typography-h4-line-height: 1;
  --ui-typography-h4-letter-spacing: 0;
  --ui-typography-h4-color: rgba(0, 0, 0, 1);
  --ui-typography-body-family: var(--paragraph);
  --ui-typography-body-size: 1.75rem;
  --ui-typography-body-weight: 300;
  --ui-typography-body-line-height: 1.5;
  --ui-typography-body-letter-spacing: 0;
  --ui-typography-body-color: rgba(0, 0, 0, 1);
  --ui-typography-body-large-size: 1.95rem;
  --ui-grid-size: 16;
  --ui-grid-gap: 2rem;
  --ui-stack-gap: var(--ui-spacer-26);
  --ui-mobile-stack-gap: var(--ui-spacer-4);

  --page-background-transition-time: 1.05s;

  //HEADER

  --header-background-color: var(--lightest-accent);
  --header-font-family: var(--paragraph);
  --header-font-size: 1.25rem;
  --header-font-weight: 400;
  --header-font-line-height: 1;
  --header-font-letter-spacing: 0;
  --header-font-color: var(--primary-color);

  //FOOTER

  --footer-background-color: var(--primary-color);

  --container-padding: clamp(1rem, 5vw, 5.625rem);

  --max-width: 1140px;
  --narrow-max-width: 980px;
  --wide-max-width: 1500px;
  --container-width: 1300px;

  --button-primary-background: var(--ui-colors-black);
  --button-primary-text: #fff;
  --button-primary-background-hover: var(--ui-colors-pink);

  --button-secondary-background: var(--ui-colors-pink);
  --button-secondary-background-hover: var(--ui-colors-white);

  --button-outline-color: var(--ui-colors-black);

  ----ui-colors-toast-background-color: var(--ui-colors-pink);

  --ui-label-weight: 700;

  --documentation-component-header-background: var(--ui-colors-pink); //#2d2d2d;
  --documentation-component-header-text-color: #000;

  --step--2: clamp(0.96rem, calc(0.89rem + 0.1vw), 0.91rem);
  --step--1: clamp(1.2rem, calc(1.05rem + 0.21vw), 1.09rem);
  --step-0: clamp(1.31rem, calc(1.24rem + 0.37vw), 1.5rem);
  --step-1: clamp(1.58rem, calc(1.46rem + 0.59vw), 1.88rem);
  --step-2: clamp(1.89rem, calc(1.71rem + 0.89vw), 2.34rem);
  --step-3: clamp(2.27rem, calc(2.01rem + 1.29vw), 2.93rem);
  --step-4: clamp(2.72rem, calc(2.36rem + 1.83vw), 3.66rem);
  --step-5: clamp(3.27rem, calc(2.75rem + 2.56vw), 4.58rem);

  --documentation-component-header-background: var(--secondary-color);
  --documentation-side-nav-background: var(--primary-color);
  --documentation-side-nav-accent: var(--tertiary-color);

  --ui-colors-error: #aa1c1c;

  --ui-animation-ease-default: ease;
  --ui-animation-ease-default-in: cubic-bezier(0.5, 0, 0.75, 0);
  --ui-animation-ease-default-out: cubic-bezier(0.25, 1, 0.5, 1);
  --ui-animation-ease-opacity: ease;
  --ui-animation-ease-color: ease;
  --ui-animation-timing-default: 0.5s;
  --ui-animation-timing-fast: 0.25s;
  --ui-animation-timing-slow: 1s;
  --color: hsl(0, 0%, 12%);
  --shadow-color: 0deg 0% 12%;
  --shadow-elevation-low: 0px 1px 1.1px hsl(var(--shadow-color) / 0.29),
    0px 1.5px 1.7px -1.2px hsl(var(--shadow-color) / 0.29),
    0px 3.5px 3.9px -2.5px hsl(var(--shadow-color) / 0.29);
  --shadow-elevation-medium: 0px 1px 1.1px hsl(var(--shadow-color) / 0.31),
    0px 3px 3.4px -0.8px hsl(var(--shadow-color) / 0.31),
    0.1px 7.3px 8.2px -1.7px hsl(var(--shadow-color) / 0.31),
    0.2px 17.7px 19.9px -2.5px hsl(var(--shadow-color) / 0.31);
  --shadow-elevation-high: 0px 1px 1.1px hsl(var(--shadow-color) / 0.29),
    0px 4.9px 5.5px -0.4px hsl(var(--shadow-color) / 0.29),
    0.1px 9px 10.1px -0.7px hsl(var(--shadow-color) / 0.29),
    0.1px 14.7px 16.5px -1.1px hsl(var(--shadow-color) / 0.29),
    0.2px 23.4px 26.3px -1.4px hsl(var(--shadow-color) / 0.29),
    0.3px 36.6px 41.2px -1.8px hsl(var(--shadow-color) / 0.29),
    0.5px 55.5px 62.4px -2.1px hsl(var(--shadow-color) / 0.29),
    0.8px 81.7px 91.9px -2.5px hsl(var(--shadow-color) / 0.29);

  --time: 0.25s;

  --border-color: #000;

  --text-input-background-color: #fff;

  --team-background-color: var(--ui-colors-white);

  --checkbox-text-color: var(--ui-colors-black);
  --checkbox-hover-color: #fff387;

  --border-radius: 1rem;

  --gutter: 2rem; //clamp(1rem, 5vw, 5.75rem);

  @media screen and (max-width: 768px) {
    --gutter: 1rem;
  }

  //   @media (prefers-color-scheme: dark) {
  //     --shadow-color: 10deg 0% 1%;
  //     --text-color: rgba(229, 224, 205, 1);
  //     --help-text-color: rgba(229, 224, 205, 0.5);
  //     --background-color: var(--ui-colors-dark-background);

  //     --button-primary-background: #dcdcdc;
  //     --button-primary-text: rgba(51, 51, 51, 1);
  //     --button-primary-background-hover: var(--ui-colors-pink);

  //     --button-secondary-background: var(--ui-colors-pink);
  //     --button-secondary-background-hover: var(--ui-colors-white);

  //     --border-color: #e5e0cd;
  //     --text-input-background-color: var(--ui-colors-dark-background);

  //     --team-background-color: #dcdcdc;

  //     --button-outline-color: #dcdcdc;

  //     --checkbox-text-color: rgba(229, 224, 205, 1);
  //     --checkbox-hover-color: rgba(229, 224, 205, 1);
  //     --header-font-color: #fff;
  //   }

  //   @media (prefers-color-scheme: light) {
  //     --shadow-color: 10deg 0% 67%;
  //     --background-color: #fbfbf5;
  //     --text-color: #000;
  //     --help-text-color: rgba(0, 0, 0, 0.5);

  //     --button-primary-background: var(--ui-colors-black);
  //     --button-primary-text: #fff;
  //     --button-primary-background-hover: var(--ui-colors-pink);

  //     --button-secondary-background: var(--ui-colors-pink);
  //     --button-secondary-background-hover: var(--ui-colors-white);

  //     --border-color: #000;

  //     --text-input-background-color: #fff;

  //     --team-background-color: var(--ui-colors-white);

  //     --checkbox-text-color: var(--ui-colors-black);
  //     --checkbox-hover-color: #fff387;

  //     --white-accent: #fff;
  //     --header-font-color: #000;
  //   }
}

// [light-mode="true"] {
//   --shadow-color: 10deg 0% 67%;
//   --background-color: #fbfbf5;
//   --text-color: #000;
//   --help-text-color: rgba(0, 0, 0, 0.5);

//   --button-primary-background: var(--ui-colors-black);
//   --button-primary-text: #fff;
//   --button-primary-background-hover: var(--ui-colors-pink);

//   --button-secondary-background: var(--ui-colors-pink);
//   --button-secondary-background-hover: var(--ui-colors-white);

//   --border-color: #000;

//   --text-input-background-color: #fff;

//   --team-background-color: var(--ui-colors-white);

//   --checkbox-text-color: var(--ui-colors-black);
//   --checkbox-hover-color: #fff387;

//   --header-font-color: #000;
// }

// [dark-mode="true"] {
//   --shadow-color: 10deg 0% 1%;
//   --text-color: rgba(229, 224, 205, 1);
//   --help-text-color: rgba(229, 224, 205, 0.5);
//   --background-color: var(--ui-colors-dark-background);

//   --button-primary-background: #dcdcdc;
//   --button-primary-text: rgba(51, 51, 51, 1);
//   --button-primary-background-hover: var(--ui-colors-pink);

//   --button-secondary-background: var(--ui-colors-pink);
//   --button-secondary-background-hover: var(--ui-colors-white);

//   --border-color: #e5e0cd;
//   --text-input-background-color: var(--ui-colors-dark-background);

//   --team-background-color: #dcdcdc;

//   --button-outline-color: #dcdcdc;

//   --checkbox-text-color: rgba(229, 224, 205, 1);
//   --checkbox-hover-color: rgba(229, 224, 205, 1);

//   --header-font-color: #fff;
// }

[data-theme="app"] {
  // --background-color: #466167;
  --background-color: #fbfbf5;
}

[data-theme="site"] {
  --background-color: #fff;
}
