:root {
  --ui-colors-teal: #142d33;
  --ui-colors-pink: #f070d9;
  --ui-colors-red: #c51c01;
  --ui-colors-gold: rgba(217, 149, 0, 1);
  --ui-colors-yellow: #f2b518;
  --ui-colors-orange: rgba(255, 128, 62, 1);
  --ui-colors-green: rgba(41, 135, 119, 1);
  --ui-colors-gray: #adadad;
  --ui-colors-black: rgba(0, 0, 0, 1);
  --ui-colors-light-text: rgba(255, 255, 255, 1);
  --ui-colors-white: rgba(255, 255, 255, 1);
  --ui-colors-background: rgba(243, 242, 238, 1);
  --ui-colors-mono-gray-3: rgba(180, 180, 180, 1);
  --ui-colors-mono-black: rgba(37, 37, 37, 1);
  --ui-colors-mono-gray-4: rgba(80, 80, 80, 1);
  --ui-colors-mono-gray-2: rgba(225, 225, 225, 1);
  --ui-colors-primary-pink: rgba(231, 91, 128, 1);
  --ui-colors-error: hsl(339, 81%, 69%);
  --ui-colors-success: rgba(0, 123, 64, 1);
  --ui-colors-dark-background: rgba(51, 51, 51, 1);
  --ui-colors-purple: #836ddb;
}
