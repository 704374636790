:root {
  --ui-font-screen-width: 1440;
  --ui-font-body: 24;
  --ui-font-body-min: 1.5rem;
  --ui-font-body-max: 2rem;
  --ui-font-body-vw: calc(
    var(--ui-font-body) / var(--ui-font-screen-width) * (100vw - 400px)
  );

  /* Body, 20px on 1440px screens */
  --ui-font-size-body: clamp(
    var(--ui-font-body-min),
    var(--ui-font-body-vw),
    var(--ui-font-body-max)
  );

  --ui-font-h1: var(--ui-typography-h1-pixelSize);
  --ui-font-size-h1: clamp(
    var(--ui-font-body-min) * (var(--ui-font-h1) / var(--ui-font-body)),
    var(--ui-font-body-vw) * (var(--ui-font-h1) / var(--ui-font-body)),
    var(--ui-font-body-max) * (var(--ui-font-h1) / var(--ui-font-body))
  );

  --ui-font-h2: var(--ui-typography-h2-pixelSize);
  --ui-font-size-h2: clamp(
    var(--ui-font-body-min) * (var(--ui-font-h2) / var(--ui-font-body)),
    var(--ui-font-body-vw) * (var(--ui-font-h2) / var(--ui-font-body)),
    var(--ui-font-body-max) * (var(--ui-font-h2) / var(--ui-font-body))
  );

  --ui-font-h3: var(--ui-typography-h3-pixelSize);
  --ui-font-size-h3: clamp(
    var(--ui-font-body-min) * (var(--ui-font-h3) / var(--ui-font-body)),
    var(--ui-font-body-vw) * (var(--ui-font-h3) / var(--ui-font-body)),
    var(--ui-font-body-max) * (var(--ui-font-h3) / var(--ui-font-body))
  );

  --ui-font-h4: var(--ui-typography-h4-pixelSize);
  --ui-font-size-h4: clamp(
    var(--ui-font-body-min) * (var(--ui-font-h4) / var(--ui-font-body)),
    var(--ui-font-body-vw) * (var(--ui-font-h4) / var(--ui-font-body)),
    var(--ui-font-body-max) * (var(--ui-font-h4) / var(--ui-font-body))
  );

  --ui-font-h5: var(--ui-typography-h5-pixelSize);
  --ui-font-size-h5: clamp(
    var(--ui-font-body-min) * (var(--ui-font-h5) / var(--ui-font-body)),
    var(--ui-font-body-vw) * (var(--ui-font-h5) / var(--ui-font-body)),
    var(--ui-font-body-max) * (var(--ui-font-h5) / var(--ui-font-body))
  );
}

body {
  font-family: var(--paragraph);
  font-size: 1rem;
  line-height: 1.5;
}

.p {
  font-family: var(--ui-typography-body-family);
  font-size: 1rem;
  font-weight: 400;
  line-height: var(--ui-typography-body-line-height);
  letter-spacing: var(--ui-typography-body-letter-spacing);
  // color: var(--text-color);
}

.body-large {
  font-size: 1.75rem;
  line-height: 1.4;
  font-weight: 300;

  p {
    font-size: 1.75rem;
    line-height: 1.4;
    font-weight: 300;
  }
}

h1,
.h1,
[aria-level="1"] {
  font-family: var(--h1-font);
  // font-size: var(--ui-typography-h1-size);
  // font-weight: var(--ui-typography-h1-weight);
  line-height: var(--ui-typography-h1-line-height, 1);
  // letter-spacing: -0.1rem;
}

.h1 {
  //font-family: var(--h2-font);
  font-size: clamp(3rem, 10vw, 4.5rem);
}

h2,
.h2,
[aria-level="2"] {
  font-family: var(--ui-typography-h2-family);
  // font-size: var(--ui-typography-h2-size);
  // font-weight: var(--ui-typography-h2-weight);
  line-height: var(--ui-typography-h2-line-height);
  // letter-spacing: -0.1rem;
}

h3,
.h3,
[aria-level="3"] {
  font-family: var(--ui-typography-h3-family);
  // font-size: var(--ui-typography-h3-size);
  // font-weight: var(--ui-typography-h3-weight);
  // line-height: var(--ui-typography-h3-line-height);
  // letter-spacing: -0.1rem;
}

h4,
.h4,
[aria-level="4"] {
  font-family: var(--ui-typography-h4-family);
  // font-size: var(--ui-font-size-h4);
  // font-weight: 400;
  // line-height: var(--ui-typography-h4-line-height);

  &.team-name {
    font-weight: 600;
  }
}

h5,
.h5,
[aria-level="5"] {
  font-family: var(--ui-typography-h5-family);
  font-size: var(--ui-font-size-h5);
  font-weight: var(--ui-typography-h5-weight);
  line-height: var(--ui-typography-h5-line-height);
}

.condensed {
  font-family: var(--light-text);
  font-weight: 300;
}

a {
  font-family: inherit;
}

b,
strong {
  font-weight: 900 !important;
}

.type-2 {
  font-size: clamp(1.25rem, 5vw + 1rem, 2.5rem);

  @media screen and (max-width: 1400px) {
    font-size: clamp(1rem, 2vw + 1rem, 2rem);
  }
}

.type-3 {
  font-size: clamp(1.5rem, 10vw + 1.25rem, 3rem);
}

.type-4 {
  font-size: clamp(2rem, 10vw + 1.25rem, 4rem);

  @media screen and (max-width: 1100px) {
    font-size: clamp(1.5rem, 10vw + 1.25rem, 3rem);
  }
}

.type-6 {
  // font-size: clamp(3rem, 10vw + 1.25rem, 6rem);
  font-size: clamp(3.75rem, 2.9674rem + 3.913dvw, 6rem);

  @media screen and (max-width: 767px) {
    font-size: clamp(2.5rem, 2.2674rem + 3.913dvw, 5rem);
  }
}

.type-96 {
  font-size: clamp(4rem, 10vw + 1.25rem, 6rem);
  word-break: keep-all;
  line-break: normal;
  word-wrap: normal;
}

.type-8 {
  //64px - 140px
  font-size: clamp(4rem, 2.3478rem + 8.2609vi, 8.75rem);
  &.break-anywhere {
    line-break: anywhere;
  }
}

.footer {
  @media screen and (max-width: 768px) {
    .type-8 {
      //64px - 140px
      font-size: clamp(2rem, 2.1478rem + 4.2609vi, 6.75rem);
      &.break-anywhere {
        line-break: anywhere;
      }
    }
  }
}

.intro-video-text {
  font-size: clamp(3rem, 10vw + 1.25rem, 6rem);

  @media screen and (max-width: 1400px) {
    font-size: clamp(2rem, 10vw + 1.25rem, 5rem);
  }
}

.text-super-small {
  font-size: 0.75rem;
}

.h1 {
  font-size: clamp(2rem, 10vw, 3.5rem);
}

.h2 {
  font-size: clamp(1.5rem, 10vw, 2.5rem);
  font-weight: bold;
}

.h3 {
  font-size: clamp(1.25rem, 10vw, 2rem);
}