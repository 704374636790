@use "breakpoints";

//////Display//////

.display-block,
.block {
  display: block;
}

.display-flex,
.flex {
  display: flex;
}

.display-grid,
.grid {
  display: grid;
}

//////Colors///////
.text-color-white {
  color: #fff;
}

.text-color-dark {
  color: #000;
}

.text-color-orange {
  color: var(--orange-accent);
}

.text-color-yellow {
  color: var(--yellow-accent);
}

.text-color-red {
  color: var(--red-accent);
}

.text-color-green {
  color: var(--green-accent);
}

.text-color-pink {
  color: var(--pink-accent);
}

.text-color-blue {
  color: var(--blue-accent);
}

.text-color-neon-yellow {
  color: var(--neon-yellow-accent);
}

.text-color-gray {
  color: #6e6e6e;
}

/////Alignment//////

.align-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.place-center {
  display: grid;
  place-content: center;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: left;
}

//////Size//////

.full-height {
  height: 100vh !important;
}

.full-width {
  width: 100% !important;
}

.full-viewport {
  width: 100vw;
}

//////Typography//////

.text-light {
  font-weight: 100;
  font-size: 2rem;
}

.text-100 {
  font-weight: 100;
}

.text-200 {
  font-weight: 200;
}

strong {
  font-weight: 500;
}

// //////Margin AND PADDING//////

// $spaceamounts: (
// 	0,
// 	4,
// 	8,
// 	12,
// 	16,
// 	20,
// 	24,
// 	28,
// 	32,
// 	36,
// 	40,
// 	44,
// 	48,
// 	52,
// 	56,
// 	60,
// 	64,
// 	68,
// 	72,
// 	76,
// 	80,
// 	84,
// 	88,
// 	92,
// 	96,
// 	100,
// 	104,
// 	108,
// 	112,
// 	120,
// 	130,
// 	146,
// 	160,
// 	320
// );
// $sides: (top, bottom, left, right);

// @each $space in $spaceamounts {
// 	@each $side in $sides {
// 		.m#{str-slice($side, 0, 1)}-#{$space} {
// 			margin-#{$side}: #{$space}px;
// 		}

// 		.p#{str-slice($side, 0, 1)}-#{$space} {
// 			padding-#{$side}: #{$space}px;
// 		}
// 	}
// }

// @each $space in $spaceamounts {
// 	@each $side in $sides {
// 		@include breakpoint(mobile) {
// 			.mobile\:m#{str-slice($side, 0, 1)}-#{$space} {
// 				margin-#{$side}: #{$space}px;
// 			}
// 		}
// 	}
// }

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-gutter {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.px-gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.r-gutter {
  right: var(--gutter);
}

.l-gutter {
  left: var(--gutter);
}

//////Effects//////

.multiply {
  mix-blend-mode: multiply;
}

//////Containers/////

.max-content {
  width: max-content;
  max-width: 100%;
}

.max-width {
  width: var(--max-width);
  max-width: 86vw;
}

.wide-max-width {
  width: var(--wide-max-width);
  max-width: 90vw;
}

.full-width-gutter {
  max-width: 90vw;
}

.width-100 {
  width: 100%;
}

.max-45 {
  max-width: 45ch;
}

.max-65 {
  max-width: 65ch;
}

//////Display//////

.desktop-only {
  display: none;
  @include breakpoints.breakpoint(phablet) {
    display: unset;
  }
}

.mobile-only {
  display: none;
  @include breakpoints.breakpoint(mobile) {
    display: block;
  }
}

.phablet-only {
  display: none;
  @include breakpoints.breakpoint(max-phablet) {
    display: block;
  }
}

.mobile\:reverse {
  @include breakpoints.breakpoint(mobile) {
    display: flex !important;
    flex-direction: column-reverse;
  }
}

.huge-gap {
  margin-bottom: 50vh;
}

.text-decoration-none {
  text-decoration: none;
}

.wrapper {
  margin-inline: auto;
  padding-inline: 1rem;
  max-width: 60rem;
}

// .gap-0 {
// 	gap: 0;
// }

// .gap-1 {
// 	gap: 1rem;
// }

// .gap-2 {
// 	gap: 2rem;
// }

// .gap-3 {
// 	gap: 3rem;
// }

// .gap-4 {
// 	gap: 4rem;
// }

.no-flex {
  display: block;
  gap: 0;
}

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.label {
  display: block;
  font-size: 1.25rem;
  text-align: left;
  text-transform: capitalize;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}

.relative {
  position: relative;
}

.small {
  font-size: 1.25rem;
  font-weight: 400;
}

.half {
  max-width: calc(50vw - 4rem);
  width: calc(50vw - 4rem);

  @media screen and (max-width: 1024px) {
    max-width: 600px;
    width: calc(100vw - 4rem);
  }
}

.min-height-100 {
  min-height: 100vh;
}

.fixed {
  position: fixed;
}

.z-important {
  z-index: 100000;
}

.m-auto {
  margin: auto;
}

.inset-0 {
  inset: 0;
}

.current-text-color {
  color: var(--text-color);
}

.column {
  flex-direction: column;
}

.transition {
  transition: all 0.3s ease;
}

.cursor {
  cursor: pointer;
}

.light-text {
  color: #6e6e6e !important;
}

.text-error {
  color: var(--ui-colors-error);
}

.number {
  color: var(--ui-colors-pink);
  margin-right: 1rem;
}

.unlock-step {
  opacity: 0.25;
  &.active {
    opacity: 1;
  }
}

.main-textarea {
  width: 800px;
  max-width: 100%;
  background-color: #000;
  color: var(--text-color);
  border: solid 1px #fff;
  padding: 1rem 1rem 1rem 2rem;
  // border-radius: var(--border-radius);
  display: flex;
  transition: all 0.3s ease;

  &::placeholder {
    color: #ccc;
  }

  &:focus-within {
    border-color: var(--ui-colors-purple);
    background-color: rgba(0, 0, 0, 0.8);
  }

  textarea {
    outline: none;
    padding-right: 3rem;
    font-size: 1.25rem;
    color: #fff;
  }

  &.no-button {
    padding-right: 1rem;
    textarea {
      padding-right: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0.75rem 1rem 0.75rem 1rem;
    textarea {
      font-size: 1rem;
      // line-height: 1.3;
      // &::placeholder {
      //   line-height: 1;
      // }
      padding-right: 0;
    }
  }
}

.card-grid {
  grid-template-columns: repeat(2, clamp(50%, 50vw, 400px));
  width: 100%;
  gap: 1rem;

  .flip-card {
    width: 100%;
    max-width: 600px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
  }
}

.container {
  width: 1200px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 1rem;
  }
}

.grid-block {
  flex: 0 0 auto;
}

.stack {
  display: flex;
  flex-direction: column;
}
