@mixin breakpoint($point) {
	@if $point == desktop {
		@media (min-width: 70em) {
			@content;
		}
	} @else if $point == laptop {
		@media (min-width: 64em) {
			@content;
		}
	} @else if $point == tablet {
		@media (min-width: 50em) {
			@content;
		}
	} @else if $point == phablet {
		@media (min-width: 37.5em) {
			@content;
		}
	} @else if $point == max-phablet {
		@media (max-width: 1023px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 37.5em) {
			@content;
		}
	} @else if $point == portrait {
		@media (max-width: 834px) {
			@content;
		}
	}
}

@mixin mobile {
	@media (min-width: 576px) {
		@content;
	}
}
@mixin large-mobile {
	@media (min-width: 576px) {
		@content;
	}
}
@mixin portrait {
	@media (min-width: 834px) {
		@content;
	}
}
@mixin tablet-portrait {
	@media (min-width: 834px) {
		@content;
	}
}
@mixin tablet-landscape {
	@media (min-width: 1024px) {
		@content;
	}
}
@mixin landscape {
	@media (min-width: 1112px) {
		@content;
	}
}
@mixin laptop {
	@media (min-width: 1440px) {
		@content;
	}
}
@mixin desktop-small {
	@media (min-width: 1400px) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: 1680px) {
		@content;
	}
}
@mixin large-desktop {
	@media (min-width: 1920px) {
		@content;
	}
} ;
